(<template>
  <div :class="{'in-progress': progressActive}"
       class="calendar-page is-progress-bar">
    <calendar-navigation />
    <div class="calendar-page__wrapper">
      <div class="calendar-page__content">
        <full-calendar :active-date="calendarDate"
                       :view="currentCalendarView"
                       :events="calendarJobsObj"
                       @catchcalendarinstance="catchCalendar"
                       @periodselected="handlePeriodSelection"
                       @startprogress="progressActive = true"
                       @stopprogress="progressActive = false" />
      </div>
    </div>
  </div>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';

  export default {
    asyncData({store, route, moment}) {
      let promisesArr = '';
      if (!store.state.TTCalendarsStore.calendarJobs && route.query.date && route.query.view) {
        const newDate = route.query.date;
        promisesArr = [...promisesArr, store.dispatch('TTCalendarsStore/getCalendarJobs', {date: newDate, view: route.query.view})];
      } else if (!store.state.TTCalendarsStore.calendarJobs && (!route.query.date || !route.query.view)) {
        const date = route.query.date ? route.query.date : moment().format('YYYY-MM-DD');
        const view = route.query.view || 'week';
        promisesArr = [...promisesArr, store.dispatch('TTCalendarsStore/getCalendarJobs', {date, view})];
      }
      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'calendar-navigation': () => import('@/components/calendars/CalendarNavigation'),
      'full-calendar': () => import('@/components/calendars/FullCalendar')
    },
    data() {
      return {
        progressActive: true,
        calendarInstance: ''
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['enterpriseMemberJobsScope', 'userIsInterpreter']),
      ...mapState('TTCalendarsStore', {
        calendarJobs: 'calendarJobs',
        queryDate: 'queryDate',
        queryView: 'queryView'
      }),
      calendarJobsObj() { return this.calendarJobs || {}; },
      calendarDate() { return this.$route.query.date ? this.$route.query.date : ''; },
      clientWidth() { return this.$store.state.GlobalStore.clientWidth || 768; },
      currentCalendarView() {
        switch (this.$route.query.view) {
          case 'week':
            return 'timeGridWeek';
          case 'month':
            return 'dayGridMonth';
          case 'day':
            return 'timeGridDay';
        }
      }
    },
    watch: {
      $route() {
        if (this.$route.query.view != this.queryView) {
          this.refetchData();
        } else {
          const routeDateArr = this.$route.query.date ? this.$route.query.date.split('-') : [];
          const routeDateMoment = this.$moment().year(routeDateArr[0]).month(routeDateArr[1] - 1).date(routeDateArr[2]);
          const routeDateMomentMonday = this.$moment(routeDateMoment).day(1);
          const cachedDateArr = this.queryDate ? this.queryDate.split('-') : [];
          const cachedDateMoment = this.$moment().year(cachedDateArr[0]).month(cachedDateArr[1] - 1).date(cachedDateArr[2]);
          const cachedDateMomentMonday = this.$moment(cachedDateMoment).day(1);
          if (this.$route.query.view == 'month') {
            if (routeDateMoment.month() != cachedDateMoment.month()) this.refetchData();
          } else if (this.$route.query.view == 'week') {
            if (routeDateMomentMonday.isoWeek() != cachedDateMomentMonday.isoWeek()) {
              if (routeDateMoment.day() == 0) {
                if ((+routeDateMomentMonday.isoWeek() - 1) != cachedDateMomentMonday.isoWeek()) this.refetchData();
              } else if (cachedDateMoment.day() == 0) {
                if (routeDateMomentMonday.isoWeek() != (+cachedDateMomentMonday.isoWeek() - 1)) this.refetchData();
              } else {
                this.refetchData();
              }
            }
          } else {
            if (this.$route.query.date != this.queryDate) this.refetchData();
          }
        }
      },
      clientWidth() { this.setDayView(); },
      enterpriseMemberJobsScope() {
        this.refetchData();
      }
    },
    methods: {
      catchCalendar(inst) { this.calendarInstance = inst; },
      handlePeriodSelection(data = {}) {
        if (this.$moment(data.startStr).isBefore(this.$moment())) {
          this.calendarInstance.unselect();
          return;
        }
        if (this.userIsInterpreter) {
          this.$store.commit('ModalStore/setModal', {
            component: 'add-private-event-modal',
            classes: ['sk-modal--new'],
            data: {
              title: this.$gettext('Add private event'),
              data: {
                startTime: data.startStr,
                finishTime: data.endStr
              }
            }
          });
        }
      },
      setDayView() {
        if (this.clientWidth < 768 && this.$route.query.view != 'day') {
          this.setCalendarView('day');
        }
      },
      setCalendarView(view) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            view: view || this.$route.query.view || 'week',
            date: this.$route.query.date || this.$moment().format('YYYY-MM-DD')
          }
        });
      },
      refetchData() {
        this.$store.commit('TTCalendarsStore/clearStore');
        this.$store.commit('GlobalStore/startProgress');
        this.$options.asyncData({
          store: this.$store,
          route: this.$route
        }).then(() => {
          this.$store.commit('GlobalStore/stopProgress');
        });
      }
    },
    mounted() {
      if (!this.$route.query.view || !this.$route.query.date) {
        this.setCalendarView(this.clientWidth < 768 ? 'day' : '');
      } else {
        this.setDayView();
      }
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('TTCalendarsStore/clearStore');
      this.$store.commit('TTPrivateEventsStore/clearStore');
      next();
    }
  };
</script>

<style scoped>
  .calendar-page {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    padding: 0 15px 20px;
  }

  .calendar-page__wrapper {
    padding: 5px 20px 20px;
    background-color: #fff;
  }

  #app.salita-facelifted .calendar-page__wrapper {
    border-radius: 8px;
    box-shadow: var(--shadow-and-blur);
  }

  .calendar-page__content {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: hidden;
    height: calc(100vh - 220px);
  }

  @media (max-width: 767px) {
    .calendar-page {
      padding: 0;
    }

    .calendar-page__wrapper {
      padding: 5px 7px 20px;
    }

    .calendar-page__content {
      height: calc(100vh - 200px);
    }
  }
</style>
